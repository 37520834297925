<template>
  <div class="w-100 my-5">
    <div class="box-white d-flex flex-column">
      <div class="row w-100 mt-5 vertical-center">
        <span class="col-sm-2 m-1 font-bold font-12 alpha-07">From User ID</span>
        <input class="col-sm m-1 font-12" v-model="fromUserId" />
      </div>
      <div class="row w-100 mt-5 vertical-center">
        <span class="col-sm-2 m-1 font-bold font-12 alpha-07">To User ID</span>
        <input class="col-sm m-1 font-12" v-model="toUserId" />
      </div>
      <div class="row w-100 mt-5 vertical-center">
        <span class="col-sm-2 m-1 font-bold font-12 alpha-07">Followers for From User</span>
        <span class="col-sm m-1 font-bold font-12 alpha-07">{{ fromFollowerList.length }}</span>
      </div>
      <div class="row w-100 mt-5 vertical-center">
        <span class="col-sm-2 m-1 font-bold font-12 alpha-07">Followers for To User</span>
        <span class="col-sm m-1 font-bold font-12 alpha-07">{{ toFollowerList.length }}</span>
      </div>
      <div class="my-5 d-flex justify-content-end">
        <v-btn class="mx-5 button-normal" small @click="getLikeListTask(true)" :disabled="!fromUserId">
          <span>Load Followers for From User</span>
        </v-btn>
        <v-btn class="mx-5 button-normal" small @click="getLikeListTask(false)" :disabled="!toUserId">
          <span>Load Followers for To User</span>
        </v-btn>
        <v-btn class="mx-5 button-normal" small @click="moveFollowersTask" :disabled="(!fromUserId || !toUserId)">
          <span>Move Followers</span>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { httpsCallable } from 'firebase/functions';
import { functions, firestore } from '../../../main';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { showLoading } from '../../../functions';

export default {
  name: 'MoveFollowers',
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    }
  },
  data() {
    return {
      fromUserId: '',
      toUserId: '',
      fromFollowerList: [],
      toFollowerList: [],
      index: 0
    }
  },
  mounted() {
    if (window.location.hostname !== 'localhost') {
      this.$router.push('/');
    }
  },
  methods: {
    getLikeListTask(isFrom) {
      const targetId = isFrom ? this.fromUserId : this.toUserId;
      if (!targetId) {
        this.$toast.error('No Target ID.');
        return;
      }
      this.index = 0;
      const loader = showLoading(this);
      const q = query(collection(firestore, 'like'), where('targetType', '==', 1), where('targetId', '==', targetId));
      getDocs(q).then(querySnapshot => {
        loader.hide();
        const list = [];
        querySnapshot.forEach(doc => list.push(doc.data().userId));
        if (isFrom) {
          this.fromFollowerList = list;
        } else {
          this.toFollowerList = list;
        }
      });
    },
    moveFollowersTask() {
      if (!this.fromUserId) {
        this.$toast.info('Please enter From User ID.');
        return;
      }
      if (!this.toUserId) {
        this.$toast.info('Please enter To User ID.');
        return;
      }
      if (!confirm('Really?')) {
        return;
      }
      const params = {
        functionName: 'moveFollowers',
        doSet: false,
        fromUserId: this.fromUserId,
        toUserId: this.toUserId
      };
      if (this.fromFollowerList.length > 0 && this.toFollowerList.length > 0) {
        const newFollowerList = [];
        var count = 0;
        this.fromFollowerList.forEach(userId => {
          if (!this.toFollowerList.includes(userId)) {
            count++;
            if (count > this.index * 300 && count < (this.index + 1) * 300) {
              newFollowerList.push(userId);
            }
          }
        });
        params['newFollowerList'] = newFollowerList;
        if (newFollowerList.length === 0) {
          this.$toast.info('Done!');
          return;
        }
      }
      this.index++;
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, this.ADMIN_FUNCTION);
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        if (response.data === this.RESULT_SUCCESS) {
          this.$toast.success(this.$t('success'));
        } else {
          this.$toast.error(response.data);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    }
  }
};
</script>